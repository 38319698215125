import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { Link, LanguageSelect, Nav, ModalPortal, ResponsiveImage, PopoverMenu } from '@/components';
import classNames from 'classnames';
import Menu from '@/assets/menu.svg';
import Close from '@/assets/close.svg';
import { setNavIsOpen, setNavVisible, wrapper } from '@/store';
import { isColorDark } from '@/utils';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { isIOS, isMacOs, isSafari } from 'react-device-detect';
import tailwindConfig from '@/tailwind.config';

const Header = ({ header, moduleName, metadata, backgroundColour, ...rest }) => {
  const [showHeader, setShowHeader] = useState(true);
  const [solidBG, setSolidBG] = useState(false);
  const [navColor, setNavColor] = useState();
  const [mainOpen, setMenuOpen] = useState(false);
  const nav = useRef();
  const prevScrollPos = useRef(0);
  const { store } = wrapper.useWrappedStore(rest);
  const router = useRouter();
  const { t } = useTranslation('common');
  const checkBackground = moduleName[0] === 'NotificationBar' ? backgroundColour[1] : backgroundColour[0];
  const isDark = isColorDark(checkBackground);
  const [logoWrapperSize, setLogoWrapperSize] = useState();
  const [windowWidth, setWindowWidth] = useState(0);
  const { screens } = tailwindConfig.theme;

  useEffect(() => {
    const resize = () => {
      setWindowWidth(window.innerWidth);
    };

    resize();
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (isMacOs || isIOS || isSafari) {
      setLogoWrapperSize('w-[120px] md:w-[250px] md:max-w-[unset]');
    } else {
      setLogoWrapperSize('max-w-[130px] md:max-w-[unset]');
    }
  }, []);

  useEffect(() => {
    if (checkBackground === undefined) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  }, [router, checkBackground]);

  const isTablet = windowWidth <= parseInt(screens.xl, 10);
  const isMobile = windowWidth <= parseInt(screens.md, 10);

  const onScroll = () => {
    const currentScrollPos = window.scrollY;
    setSolidBG(window.scrollY > 0 + 50);

    if (currentScrollPos > prevScrollPos.current + 10) {
      setShowHeader(false);
      store.dispatch(setNavVisible(false));
    } else if (currentScrollPos < prevScrollPos.current || window.scrollY === 0) {
      setShowHeader(true);
      store.dispatch(setNavVisible(true));
    }
    prevScrollPos.current = currentScrollPos;
  };

  const _setMenuOpen = (open) => {
    if (!open) {
      nav.current?.resetActiveMenu();
    }
    setMenuOpen(open);
    store.dispatch(setNavIsOpen(open));
  };

  const languageSwitcherTitleClass =
    '[&_.popover-title]:!text-[12px] [&_.popover-title]:!font-medium [&_li]:!font-medium [&_.popover-title]:!leading-[14px] [&_.popover-title]:!tracking-[2.4px] [&_li]:!text-[12px] [&_li]:!leading-[14px] [&_li]:!tracking-[2.4px]';

  return (
    <motion.header
      initial={{ top: '-88px' }}
      animate={{ top: showHeader ? 0 : '-89px' }}
      // transition={{ type: 'spring', stiffness: 50, damping: 15 }}
      transition={{ type: 'tween', bounce: 0, delay: 0.2, duration: 0.5 }}
      className={classNames('group fixed z-20 w-full')}
    >
      <div
        className={classNames(
          'content relative transition-colors duration-700 hover:!border-charcoal',
          mainOpen ? 'hover:bg-silk' : 'hover:bg-bone',
          mainOpen ? 'disable-scroll border-silk bg-silk' : solidBG ? 'border-charcoal/50 bg-bone' : 'border-silk/50',
          header.hotelVillaNavElements.length > 0 && !header.isVilla ? 'border-b-0' : 'border-b',
          { '!border-silk/50': navColor && !isDark && !solidBG },
          { '!border-charcoal/50': !navColor && isDark && solidBG },
          { '!border-charcoal/50': !navColor && !isDark && !solidBG },
        )}
      >
        <div className="space-between container relative flex items-center px-4 py-5 md:px-6 lg:flex-row lg:px-10">
          <div className="flex w-1/3 sm:w-2/5">
            <button
              onClick={() => _setMenuOpen(!mainOpen)}
              className={classNames(
                'flex',
                'font-montserrat text-sm font-medium uppercase not-italic leading-5',
                'h-12 cursor-pointer items-center gap-2 py-2 lg:flex-row xl:gap-2.5',
                solidBG || mainOpen || !navColor ? 'text-charcoal' : 'text-silk group-hover:text-charcoal',
              )}
              aria-label={t('general.$menu')}
            >
              <div className="relative m-1.5">
                <Menu
                  className={classNames(
                    'duration-700 group-hover:stroke-charcoal rtl:rotate-180',
                    mainOpen && '!fill-silk stroke-silk',
                    navColor && !solidBG ? '!fill-silk stroke-silk' : '!fill-charcoal stroke-charcoal',
                    mainOpen && 'opacity-0',
                    isDark && !solidBG && !navColor ? '!fill-silk stroke-silk' : '',
                  )}
                />
                <Close
                  className={classNames('absolute left-0.5 top-0 duration-700', !mainOpen && 'opacity-0')}
                  role="presentation"
                />
              </div>
              <div className="relative hidden sm:block">
                <span
                  className={classNames(
                    'interactive-01 duration-700 group-hover:text-charcoal sm:block',
                    mainOpen && 'opacity-0',
                    isDark && !solidBG ? 'text-silk' : 'text-charcoal',
                    navColor && !solidBG ? 'text-silk' : 'text-charcoal',
                  )}
                >
                  {t('general.$menu')}
                </span>
                <span
                  className={classNames(
                    'interactive-01 absolute right-0 top-0 text-center duration-700 sm:block lg:left-0 lg:right-auto',
                    !mainOpen && 'opacity-0',
                  )}
                >
                  {t('general.$close')}
                </span>
              </div>
            </button>
          </div>
          <div className="flex w-1/3 justify-center sm:w-1/5">
            <div>
              <Link link={header?.logos?.homePageLink}>
                {header?.logos?.desktopLogo && (
                  <div className={`main-logo-desktop hidden h-8 ${logoWrapperSize} md:flex`}>
                    <ResponsiveImage
                      widths={{ xs: 180, md: 130, lg: 130, xl: 130, xxl: 130 }}
                      image={header?.logos?.desktopLogo}
                      imgProps={{
                        className: `group-hover:invert !object-contain transition-all duration-700 ${solidBG || mainOpen ? 'transition-all duration-700 invert' : ''} ${isDark && !navColor && solidBG ? 'transition-all duration-700 invert' : ''} ${!isDark && !navColor && !solidBG ? 'transition-all duration-700 invert' : ''}`,
                      }}
                    />
                  </div>
                )}
                {header?.logos?.mobileLogo && (
                  <div className={`main-logo-mobile h-[22px] ${logoWrapperSize} flex md:hidden`}>
                    <ResponsiveImage
                      widths={{ xs: 150, md: 130, lg: 130, xl: 130, xxl: 130 }}
                      image={header?.logos?.mobileLogo}
                      imgProps={{
                        className: `group-hover:invert !object-contain transition-all duration-700 ${solidBG || mainOpen ? 'transition-all duration-700 invert' : ''} ${isDark && !navColor && solidBG ? 'transition-all duration-700 invert' : ''} ${!isDark && !navColor && !solidBG ? 'transition-all duration-700 invert' : ''}`,
                      }}
                    />
                  </div>
                )}
              </Link>
            </div>
          </div>
          <div className="flex w-1/3 justify-end sm:w-2/5">
            {header.languageSwitcher && (
              <div
                className={classNames(
                  'justify-right tracking-8 z-50 hidden h-[44px] cursor-pointer uppercase leading-4 duration-200 sm:block',
                  'bg-linen mr-14 items-center rounded-full text-black sm:mr-0',
                )}
              >
                <LanguageSelect
                  metadata={metadata}
                  className={classNames(
                    languageSwitcherTitleClass,
                    'z-50, ltr:mr-[10px] rtl:ml-[10px]',
                    '[&_.arrow_svg_path]:duration-700 [&_.main-button]:!h-11 [&_.main-button]:gap-1 [&_.main-button]:!border-0 [&_.main-button]:!bg-transparent [&_.popover-title]:!duration-700',
                    solidBG || mainOpen
                      ? '[&_.arrow_svg_path]:fill-charcoal [&_.popover-title]:text-charcoal'
                      : '[&_.arrow_svg_path]:fill-silk group-hover:[&_.arrow_svg_path]:fill-charcoal [&_.popover-title]:text-silk group-hover:[&_.popover-title]:!text-charcoal',
                    !isDark && !navColor && !solidBG
                      ? '[&_.arrow_svg_path]:!fill-charcoal [&_.popover-title]:!text-charcoal'
                      : '',
                    !isDark && navColor && !solidBG ? '[&_.popover-title]:text-charcoal' : '',
                  )}
                />
              </div>
            )}
            {header.primaryBookingLink && header.secondaryBookingLink === null && (
              <Link
                animate
                className={classNames(
                  'btn secondary dark px-3 duration-700 group-hover:border-charcoal group-hover:bg-charcoal sm:px-6',
                  (solidBG || mainOpen) && 'border-charcoal bg-charcoal text-charcoal',
                  !isDark && !navColor && !solidBG ? '!border-charcoal bg-charcoal !text-silk' : '',
                )}
                link={header.primaryBookingLink}
              />
            )}

            {header.primaryBookingLink && header.secondaryBookingLink && isTablet === false && (
              <div class="flex w-full justify-end">
                <Link
                  animate
                  className={classNames(
                    'btn secondary dark px-3 duration-700 group-hover:border-charcoal group-hover:bg-charcoal sm:px-6',
                    (solidBG || mainOpen) && 'border-charcoal bg-charcoal text-charcoal',
                    !isDark && !navColor && !solidBG ? '!border-charcoal bg-charcoal !text-silk' : '',
                  )}
                  link={header.primaryBookingLink}
                />
                <Link
                  animate
                  className={classNames(
                    'btn secondary dark ml-3 px-3 duration-700 group-hover:border-charcoal group-hover:bg-charcoal sm:px-6',
                    (solidBG || mainOpen) && 'border-charcoal bg-charcoal text-charcoal',
                    !isDark && !navColor && !solidBG ? '!border-charcoal bg-charcoal !text-silk' : '',
                  )}
                  link={header.secondaryBookingLink}
                />
              </div>
            )}

            {header.primaryBookingLink && header.secondaryBookingLink && isTablet && (
              <PopoverMenu
                title={t('booking.$bookNow')}
                hideArrow={true}
                titleClass={classNames(
                  'w-full btn secondary pl-0 pr-0 dark duration-700 group-hover:border-charcoal group-hover:bg-charcoal sm:px-6',
                  (solidBG || mainOpen) && 'border-charcoal bg-charcoal text-charcoal',
                  !isDark && !navColor && !solidBG ? '!border-charcoal bg-charcoal !text-silk' : '',
                )}
                bgClass={'bg-transparent'}
                className={classNames(
                  'w-2/3 [&_.content>div]:px-[14px] [&_.content>div]:py-[24px] [&_.content]:py-[12px] [&_.content]:text-charcoal [&_.main-button]:border-grey5',
                  '[&_button]:px-0 [&_button]:py-0',
                  isMobile && '[&_.pop-over]:min-w-[220px] [&_.pop-over]:pr-[16px]',
                  (solidBG || mainOpen) && 'border-charcoal bg-charcoal text-charcoal',
                  !isDark && !navColor && !solidBG ? '!border-charcoal bg-charcoal !text-silk' : '',
                )}
              >
                <Link animate link={header.primaryBookingLink} />
                <Link animate link={header.secondaryBookingLink} />
              </PopoverMenu>
            )}
          </div>
        </div>
        {header.hotelVillaNavElements.length > 0 && !header.isVilla && (
          <div
            className={classNames(
              'hotel-nav hidden h-[58px] w-full items-center justify-center gap-x-[67px] border-b border-charcoal/50 transition-colors duration-700 group-hover:!border-charcoal lg:flex',
              solidBG
                ? 'border-t border-charcoal/50 bg-bone !text-charcoal'
                : 'border-t border-silk/50 group-hover:text-charcoal',
              !isDark && !navColor && !solidBG ? 'border-t !border-charcoal/50' : '',
              { 'bg-bone !text-charcoal': !showHeader },
              { '!text-charcoal': !showHeader && isDark },
              { 'text-silk': isDark && !solidBG },
              { 'text-silk': navColor && !solidBG },
              { 'border-b border-charcoal/50 transition-all duration-700': !showHeader },
            )}
          >
            {header.hotelVillaNavElements.map((hotelItem, hotelIndex) => (
              <div key={hotelIndex} className={classNames('hotel-nav-items interactive-02')}>
                <Link link={hotelItem.link}>{hotelItem?.title || hotelItem.link.text}</Link>
              </div>
            ))}
          </div>
        )}
      </div>
      {header.hotelVillaNavElements.length > 0 && header.isVilla && (
        <ModalPortal className="w-full">
          <div className="hidden lg:fixed lg:bottom-10 lg:left-1/2 lg:flex lg:h-16 lg:-translate-x-1/2 lg:rounded-[4px] lg:bg-bone lg:p-[2px]">
            <div className="lg:flex lg:items-center lg:gap-x-8 lg:rounded-[4px] lg:border lg:border-charcoal lg:px-8">
              {header.hotelVillaNavElements.map((hotelItem, hotelIndex) => (
                <div
                  key={hotelIndex}
                  className={classNames('hotel-nav-items font-montserrat text-[14px] font-medium leading-3')}
                >
                  <Link
                    className={classNames(
                      'font-montserrat text-[14px] font-medium leading-3 text-charcoal/50 transition-all duration-700',
                      {
                        '!text-charcoal/100': hotelItem.isActive,
                      },
                    )}
                    link={hotelItem.link}
                  >
                    {hotelItem?.title || hotelItem.link.text}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </ModalPortal>
      )}
      <Nav
        open={mainOpen}
        ref={nav}
        onClose={() => setMenuOpen(false)}
        navItems={header?.mainNavItems}
        metadata={metadata}
        logoElement={header.logoElement}
        showLanguageSwitcher={header.languageSwitcher}
        mainNavImg={header.mainNavPromoImage}
        isVilla={header.isVilla}
        hotelVillaNavElements={header.hotelVillaNavElements}
        mobileBottomNavItems={header.mobileBottomNavItems}
      />
    </motion.header>
  );
};

export default Header;
